import Header from "../components/header";
import Icon from "../components/icon";
import { Link } from "react-router-dom";
import Dropdown from "react-dropdown";
import images from "../constants/images";

const Ground = () => {
    const tournamentOptions = ["All", "India", "United States"];

    return (
        <div className="categories tournaments">
            <Header title="Tournaments" />
            <div className="wrapper">
                <Dropdown
                    options={tournamentOptions}
                    placeholder="Select an Country"
                    className="dropdown teamdropdown    "
                />
                <Link to="/ground/add" className="btn add">
                    <Icon name="add" /> Add Ground
                </Link>
            </div>

            <div className="cards ">
                {Array.from({ length: 8 }).map((item) => (
                    <div className="matchse card groundcard">
                        <section>
                            <div>
                                <span>sardar patel stadium</span>
                            </div>
                        </section>
                        <div className="groundcountry">
                            <span>Country : India</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Ground;
