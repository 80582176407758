import { BrowserRouter, Route, Routes } from "react-router-dom";

import Sidebar from "../components/sidebar";

import Home from "../pages/home";
import Categories from "../pages/categories";
import Tournaments from "../pages/tournaments";
import Teams from "../pages/teams";
import Players from "../pages/players";
import Matches from "../pages/matches";
import TournamentsAdd from "../pages/tournamentsAdd";
import MatchesAdd from "../pages/matchesAdd";
import MatchesAddPlayer from "../pages/matchesAddPlayer";
import PlayersAdd from "../pages/playersAdd";
import TeamsAdd from "../pages/teamsAdd";
import GroundAdd from "../pages/groundAdd";
import Ground from "../pages/ground";

const Navigation = () => {
    return (
        <BrowserRouter>
            <div className="container">
                <Sidebar />
                <main>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/categories" element={<Categories />} />
                        <Route path="/tournaments" element={<Tournaments />} />
                        <Route path="/teams" element={<Teams />} />
                        <Route path="/players" element={<Players />} />
                        <Route path="/matches" element={<Matches />} />
                        <Route path="/matches/add" element={<MatchesAdd />} />
                        <Route
                            path="/matches/add/player"
                            element={<MatchesAddPlayer />}
                        />
                        <Route path="/players/add" element={<PlayersAdd />} />
                        <Route path="/teams/add" element={<TeamsAdd />} />
                        <Route path="ground/add" element={<GroundAdd />} />
                        <Route path="/ground" element={<Ground />} />
                        <Route
                            path="/tournaments/add"
                            element={<TournamentsAdd />}
                        />
                    </Routes>
                </main>
            </div>
        </BrowserRouter>
    );
};

export default Navigation;
