import uid from "harsh-uid";
import { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";

import Icon from "./icon";

import "react-datepicker/dist/react-datepicker.css";

const Input = ({
    label,
    type = "text",
    onChange,
    inputClass,
    labelClass,
    autoFocus,
    value,
    time,
    ...props
}) => {
    const [focused, setFocused] = useState(false);
    const [val, setVal] = useState(null);
    const [file, setFile] = useState(null);

    const inputRef = useRef(null);
    const id = uid();

    useEffect(() => {
        if (focused) {
            inputRef.current.focus();
        }
    }, [focused]);

    return (
        <>
            {type === "date" ? (
                <div className="DP">
                    <label className="date-label">{label}</label>
                    <DatePicker
                        onChange={(date) => {
                            onChange && onChange(date);
                        }}
                        className="date-picker"
                        selected={value || new Date()}
                        showTimeSelect={time}
                    />
                </div>
            ) : type === "file" ? (
                <div className="fp">
                    <label htmlFor={id}>
                        <Icon name="add_photo_alternate" />
                        {label}
                    </label>
                    <input
                        type="file"
                        style={{ display: "none" }}
                        id={id}
                        onChange={(e) => {
                            const file = e.currentTarget.files[0] || null;
                            file && setFile(URL.createObjectURL(file));
                        }}
                        accept="image/*"
                    />
                    {file && <img src={file} />}
                </div>
            ) : (
                <div className="input-container">
                    <label
                        className={labelClass + " " + (focused && "active")}
                        style={{ top: focused || val ? -10 : 10 }}
                        onClick={() => setFocused(true)}
                    >
                        {label}
                    </label>
                    <input
                        type={type}
                        onChange={(e) => {
                            onChange && onChange(e);
                            setVal(e.currentTarget.value);
                        }}
                        className={inputClass}
                        onFocus={() => setFocused(true)}
                        onBlur={() => setFocused(false)}
                        autoFocus={autoFocus}
                        ref={inputRef}
                        {...props}
                    />
                </div>
            )}
        </>
    );
};

export default Input;
