import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";

import Header from "../components/header";
import Icon from "../components/icon";

const Players = () => {
    const tournamentOptions = ["All", "Tata Ipl 2023", "T20 World Cup 2023"];
    const rollOptions = ["Batsmen", "Bowler", "All-Rounders"];

    const teamOptions = [
        "All",
        " Chennai Super Kings",
        "Gujarat Titans ",
        " Lucknow Super Giants ",
    ];

    return (
        <div className="categories tournaments">
            <Header title="Players" />
            <div className="wrapper">
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={tournamentOptions}
                        placeholder="Select an Tournament"
                        className="dropdown teamdropdown    "
                    />
                    <Dropdown
                        options={teamOptions}
                        placeholder="Select an Team"
                        className="dropdown teamdropdown"
                    />
                    <Dropdown
                        options={rollOptions}
                        placeholder="Roll"
                        className="dropdown teamdropdown    "
                    />
                </div>
                <Link to="/players/add" className="btn add">
                    <Icon name="add" /> Add Player
                </Link>
            </div>
            <div className="cards ">
                {Array.from({ length: 8 }).map((item) => (
                    <div className="card tournament teamCard playerCard">
                        <img src="https://bcciplayerimages.s3.ap-south-1.amazonaws.com/ipl/IPLHeadshot2023/57.png" />
                        <div className="info">
                            <span>Name: MS Dhoni</span>
                            <span>Team: Chennai Super Kings</span>
                            <span>Roll: Batsmen</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Players;
