import Dropdown from "react-dropdown";

import Header from "../components/header";
import Icon from "../components/icon";
import Input from "../components/input";

const TournamentsAdd = () => {
    const options = ["International", "Domestic", "Spacial"];
    const teamOptions = ["All", "India"];

    return (
        <div className="categories tournaments">
            <Header title="Tournaments > Add" />
            <div className="tournamentinput">
                <Dropdown
                    options={options}
                    placeholder="Category"
                    className="dropdown"
                />
                <div className="wrapper ">
                    <Input label="Tournaments Name" />
                </div>
                <div className="wrapper">
                    <Dropdown
                        options={teamOptions}
                        placeholder="Nationality"
                        className="dropdown teamdropdown nationality   "
                    />
                </div>

                <div className="wrapper">
                    <Input label="Start Date" type="date" />
                </div>
                <div className="wrapper">
                    <Input label="End Date" type="date" />
                </div>
                <button className="btn cat-btn create">Create</button>
            </div>
        </div>
    );
};

export default TournamentsAdd;
