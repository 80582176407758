import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";

import Header from "../components/header";
import Icon from "../components/icon";

const Tournaments = () => {
    const options = ["All", "International", "Domestic", "Spacial"];

    return (
        <div className="categories tournaments">
            <Header title="Tournaments" />
            <div className="wrapper">
                <Dropdown
                    options={options}
                    placeholder="Select an option"
                    className="dropdown"
                    value={options[0]}
                />
                <Link to="/tournaments/add" className="btn add">
                    <Icon name="add" /> Add Tournament
                </Link>
            </div>
            <div className="cards">
                {Array.from({ length: 8 }).map((item) => (
                    <div className="card tournament">
                        <img src="https://img.cricketworld.com/images/f-123459/ipl-logo-22.jpg" />
                        <div className="info">
                            <span> TATA IPL 2023</span>
                            <span>09-08-2023 06:11 PM</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Tournaments;
