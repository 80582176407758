import Dropdown from "react-dropdown";

import Input from "../components/input";
import Header from "../components/header";

const GroundAdd = () => {
    const tournamentOptions = ["India", "China", "United States"];

    return (
        <div className="categories tournaments">
            <Header title="Ground > Add" />
            <div className="machesinput">
                <div className="matche">
                    <Input label="Ground Name" />
                </div>
                <div className="matche">
                    <Input label="Ground Location" />
                </div>
                <div className="wrapper ">
                    <Dropdown
                        options={tournamentOptions}
                        placeholder="Ground Country"
                        className="dropdown"
                    />
                </div>
            </div>
            <button className="btn cat-btn create">Create</button>
        </div>
    );
};

export default GroundAdd;
