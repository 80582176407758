import Dropdown from "react-dropdown";
import { useEffect, useState } from "react";

import images from "../constants/images";

import Header from "../components/header";
import Icon from "../components/icon";

const MatchesAddPlayer = () => {
    const [teamAPlayers, setTeamAPlayers] = useState([]);
    const [teamBPlayers, setTeamBPlayers] = useState([]);

    const tournamentOptions = ["Tata Ipl 2023", "T20 World Cup 2023"];
    const FirstOptions = ["India"];
    const SecondOptions = ["India"];
    const nationalityOptions = ["sardar patel stadium"];

    useEffect(() => {
        const arr = [];
        for (let i = 0; i < 20; i++) {
            arr[i] = { active: false };
        }
        setTeamAPlayers(arr);
        setTeamBPlayers(arr);
    }, []);

    return (
        <div className="categories tournaments">
            <Header title="Matches > Add-Player" />
            <div className="addplayer">
                <section>
                    <div className="playerteam">
                        <h4>Team A (sardar patel stadium)</h4>
                        <h4>
                            {
                                teamAPlayers.filter(
                                    (item) => item.active === true
                                ).length
                            }{" "}
                            / {teamAPlayers.length}
                        </h4>
                    </div>
                    <div className="card">
                        {teamAPlayers.map((item, index) => (
                            <div
                                className={
                                    "playername " + (item.active && "active")
                                }
                                onClick={() => {
                                    const updatedteamAPlayers =
                                        teamAPlayers.map((player, i) =>
                                            i === index
                                                ? {
                                                      ...player,
                                                      active: !player.active,
                                                  }
                                                : player
                                        );
                                    setTeamAPlayers(updatedteamAPlayers);
                                }}
                            >
                                {item.active && <Icon name="done" />} Mahendra
                                Singh Pansingh Dhoni
                            </div>
                        ))}
                    </div>
                </section>
                <section>
                    <div className="playerteam">
                        <h4>
                            {
                                teamBPlayers.filter(
                                    (item) => item.active === true
                                ).length
                            }{" "}
                            / {teamBPlayers.length}
                        </h4>{" "}
                        <h5>Team B (sardar patel stadium)</h5>
                    </div>
                    <div className="card">
                        {teamBPlayers.map((item, index) => (
                            <div
                                className={
                                    "playername " + (item.active && "active")
                                }
                                onClick={() => {
                                    const updatedteamBPlayers =
                                        teamBPlayers.map((player, i) =>
                                            i === index
                                                ? {
                                                      ...player,
                                                      active: !player.active,
                                                  }
                                                : player
                                        );
                                    setTeamBPlayers(updatedteamBPlayers);
                                }}
                            >
                                {item.active && <Icon name="done" />} Mahendra
                                Singh Pansingh Dhoni
                            </div>
                        ))}
                    </div>
                </section>
            </div>
            <button className="btn cat-btn create">Create</button>
        </div>
    );
};

export default MatchesAddPlayer;
