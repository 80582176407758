import Dropdown from "react-dropdown";

import Header from "../components/header";
import Input from "../components/input";

const TeamsAdd = () => {
    const teamOptions = ["India"];
    const nationalityOptions = ["sardar patel stadium"];

    return (
        <div className="categories tournaments">
            <Header title="Teams > Add" />

            <div className="wrapper">
                <div className="add-category">
                    <div className="teaminput">
                        <div className="wrapper">
                            <Input label="Team Full Name" />
                        </div>
                        <div className="wrapper">
                            <Input label="Team Short Name" />
                        </div>

                        <div className="wrapper">
                            <Dropdown
                                options={teamOptions}
                                placeholder="Nationality"
                                className="dropdown teamdropdown nationality"
                            />
                        </div>
                        <div className="wrapper">
                            <Dropdown
                                options={nationalityOptions}
                                placeholder="Stadium"
                                className="dropdown teamdropdown nationality"
                            />
                        </div>

                        <div className="matche teamimage">
                            <Input label="Team Image" type="file" />
                        </div>
                    </div>
                    <button className="btn cat-btn"> Add Team</button>
                </div>
            </div>
        </div>
    );
};

export default TeamsAdd;
