import Dropdown from "react-dropdown";
import { useState } from "react";

import Header from "../components/header";
import Input from "../components/input";

const PlayersAdd = () => {
    const [roll, setRoll] = useState(null);

    const tournamentOptions = [
        " Chennai Super Kings",
        "Gujarat Titans ",
        " Lucknow Super Giants ",
    ];
    const teamOptions = ["India"];
    const rollOptions = ["Batsmen", "Bowler", "All-Rounders"];
    const batsmenOptions = [
        "Opener",
        "Top-Order",
        "Middle-Order",
        "Finishers",
        "All-Rounders",
        "Specialist",
        "Aggressive",
        "Technically Sound",
        "Adaptive",
    ];
    const bowlerOptions = [
        "Fast ",
        "Spin",
        "Medium-Pace",
        "Fast Medium",
        "All-Rounders",
    ];

    return (
        <div className="categories tournaments">
            <Header title="Players > Add" />

            <div className="machesinput">
                <div className="matche">
                    <Input label="Player Image" type="file" />
                </div>
                <div className="matche">
                    <Input label="Player Name" />
                </div>
                <div className="matche">
                    <Input label="Player Short Name" />
                </div>
                <div className="matche">
                    <Input label="Player City" />
                </div>
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={tournamentOptions}
                        placeholder="Team"
                        className="dropdown teamdropdown"
                    />
                </div>
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={teamOptions}
                        placeholder="Nationality"
                        className="dropdown teamdropdown"
                    />
                </div>
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={rollOptions}
                        placeholder="Roll"
                        className="dropdown teamdropdown"
                        onChange={(e) => setRoll(e.value)}
                    />
                </div>
                {roll === "Batsmen" && (
                    <div className="dropdown-wrapper">
                        <Dropdown
                            options={batsmenOptions}
                            placeholder="Betting Type"
                            className="dropdown teamdropdown"
                        />
                    </div>
                )}
                {roll === "Bowler" && (
                    <div className="dropdown-wrapper">
                        <Dropdown
                            options={bowlerOptions}
                            placeholder="Bowling Type"
                            className="dropdown teamdropdown"
                        />
                    </div>
                )}

                <div className="matche">
                    <Input label="Player Birthdate" type="date" />
                </div>
            </div>
            <button className="btn cat-btn create">Create</button>
        </div>
    );
};

export default PlayersAdd;
