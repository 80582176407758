import Modal from "react-modal";

import Header from "../components/header";
import Icon from "../components/icon";
import { useState } from "react";
import Input from "../components/input";

const Categories = () => {
    const [showModal, setShowModal] = useState(false);

    const modalStyle = {
        content: {
            width: 300,
            height: 200,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: 12,
            background: "#000",
        },
    };

    return (
        <div className="categories">
            <Header title="Categories" />
            <Modal
                isOpen={showModal}
                style={modalStyle}
                onRequestClose={() => setShowModal(false)}
            >
                <div className="add-category">
                    <Input label="Category Name" />
                    <button className="btn cat-btn">Add Category</button>
                </div>
            </Modal>
            <button className="btn add" onClick={() => setShowModal(true)}>
                <Icon name="add" /> Add Category
            </button>
            <div className="cards">
                <div className="card category">International</div>
                <div className="card category">Domestic</div>
                <div className="card category">Spacial</div>
            </div>
        </div>
    );
};

export default Categories;
