import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";

import images from "../constants/images";

import Input from "../components/input";
import Header from "../components/header";
import Icon from "../components/icon";

const MatchesAdd = () => {
    const tournamentOptions = ["Tata Ipl 2023", "T20 World Cup 2023"];
    const FirstOptions = ["India"];
    const SecondOptions = ["India"];
    const nationalityOptions = ["sardar patel stadium"];

    return (
        <div className="categories tournaments">
            <Header title="Matches > Add" />
            <div className="machesinput">
                <div className="wrapper ">
                    <Dropdown
                        options={tournamentOptions}
                        placeholder="Tournament"
                        className="dropdown"
                    />
                </div>
                <div className="wrapper ">
                    <Dropdown
                        options={FirstOptions}
                        placeholder="Team A"
                        className="dropdown"
                    />
                </div>
                <div className="wrapper ">
                    <Dropdown
                        options={SecondOptions}
                        placeholder="Team B"
                        className="dropdown"
                    />
                </div>
                <div className="matche" style={{ marginTop: 30 }}>
                    <Input label="Start Time" type="date" time />
                </div>
                <div className="matche">
                    <Input label="End Time" type="date" time />
                </div>
                <div className="wrapper">
                    <Dropdown
                        options={nationalityOptions}
                        placeholder="Stadium"
                        className="dropdown teamdropdown nationality"
                    />
                </div>
            </div>
            <>
                <Link to="/matches/add/player" className="btn cat-btn create">
                    Next
                </Link>
            </>
        </div>
    );
};

export default MatchesAdd;
