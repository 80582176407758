import Dropdown from "react-dropdown";

import Header from "../components/header";
import Icon from "../components/icon";
import { Link } from "react-router-dom";

const Teams = () => {
    const options = ["All", "International", "Domestic", "Spacial"];
    const tournamentOptions = ["All", "Tata Ipl 2023", "T20 World Cup 2023"];

    return (
        <div className="categories tournaments">
            <Header title="Teams" />

            <div className="wrapper">
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={options}
                        placeholder="Select an Category"
                        className="dropdown"
                    />
                    <Dropdown
                        options={tournamentOptions}
                        placeholder="Select an Tournament"
                        className="dropdown teamdropdown"
                    />
                </div>
                <Link to="/teams/add" className="btn add">
                    <Icon name="add" /> Add Team
                </Link>
            </div>

            <div className="cards ">
                {Array.from({ length: 8 }).map((item) => (
                    <div className="card tournament teamCard">
                        <img src="https://img1.hscicdn.com/image/upload/f_auto/lsci/db/PICTURES/CMS/313400/313421.logo.png" />
                        <div className="info">
                            <span> Chennai Super Kings</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Teams;
