import Dropdown from "react-dropdown";
import { Link } from "react-router-dom";

import images from "../constants/images";

import Header from "../components/header";
import Icon from "../components/icon";

const Players = () => {
    const tournamentOptions = ["All", "Tata Ipl 2023", "T20 World Cup 2023"];

    const teamOptions = ["All", " Live", "Upcoming ", " Completed "];

    return (
        <div className="categories tournaments">
            <Header title="Matches" />
            <div className="wrapper">
                <div className="dropdown-wrapper">
                    <Dropdown
                        options={tournamentOptions}
                        placeholder="Select an Tournament"
                        className="dropdown teamdropdown    "
                    />
                    <Dropdown
                        options={teamOptions}
                        placeholder="Select an Status"
                        className="dropdown teamdropdown"
                    />
                </div>
                <Link to="/matches/add" className="btn add">
                    <Icon name="add" /> Add Match
                </Link>
            </div>
            <div className="cards ">
                {Array.from({ length: 8 }).map((item) => (
                    <div className="matchse card">
                        <section>
                            <div className="team">
                                <img src="https://img1.hscicdn.com/image/upload/f_auto/lsci/db/PICTURES/CMS/313400/313421.logo.png" />
                                <div className="teamName">
                                    Chennai Super Kings
                                </div>
                            </div>
                            <img src={images.vs} id="vs" />
                            <div className="team">
                                <img src="https://staticg.sportskeeda.com/editor/2023/05/98681-16832935306665-1920.jpg" />
                                <div className="teamName">Gujarat Titans</div>
                            </div>
                        </section>
                        <section>
                            <div>
                                <span>Start Time: 09-08-2023 06:11 PM</span>
                                <span>Status: Upcoming</span>
                            </div>
                        </section>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Players;
